import React from "react";
import "./App.css";
import Header from "./modules/header/Header";
import Engage from "./modules/engage/Engage";
import Valueproposition from "./modules/valueproposition/Valueproposition";
import Us from "./modules/us/Us";
import Footer from "./modules/footer/Footer";
import Bsgt from "./modules/growthbs/Bsgt";
import Targets from "./modules/targets/Targets";
import Banner from "./modules/banner/Banner";

function Home() {
  return (
    <div className="home">
      <Header />
      <Engage />
      <Valueproposition />
      <Us />
      <Banner />
      { //<Targets />
      }
      <Bsgt />
      <Footer />
    </div>
  );
}

export default Home;
