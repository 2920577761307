import React from 'react';
//import './header.css';


function Valueproposition() {
    return (
        <div className="card" style={styles.contentmaster}>
            <div className="card" style={styles.content}>
                <div className="preTitle" style={styles.pretitlediv}>
                    <p style={styles.pretitle}>Apps personalizadas</p>
                </div>
                <div className="titlediv" style={styles.titlediv}>
                    <p style={styles.title}>Apps multiplataforma</p>
                </div>
                <div className="descriptiondiv" style={styles.descriptiondiv}>
                    <p style={styles.description}>Somo expertos en desarrollar software multiplataforma, aplicaciones para iOS o Android con alcance internacional y desarrollo de sitios web.</p>
                </div>
                <div className="knowmorediv" style={styles.knowmorediv}>
                    <p style={styles.knowmore}>Conoce más</p>
                </div>
                <div className="colorbardiv" style={styles.colorbardiv}>

                </div>
            </div>
            <div className="card" style={styles.content}>
                <div className="preTitle" style={styles.pretitlediv}>
                    <p style={styles.pretitle}>Conexiones digitales</p>
                </div>
                <div className="titlediv" style={styles.titlediv}>
                    <p style={styles.title}>Internet Of Things</p>
                </div>
                <div className="descriptiondiv" style={styles.descriptiondiv}>
                    <p style={styles.description}>Desarrollamos ecosistemas digitales mediante sensores en aparatos y/o secciones en su hogar u oficina para monitoreo desde cualquier lugar.</p>
                </div>
                <div className="knowmorediv" style={styles.knowmorediv}>
                    <p style={styles.knowmore2}>Conoce más</p>
                </div>
                <div className="colorbardiv" style={styles.colorbardiv2}>

                </div>
            </div>
            <div className="card" style={styles.content}>
                <div className="preTitle" style={styles.pretitlediv}>
                    <p style={styles.pretitle}>Siempre comunicado</p>
                </div>
                <div className="titlediv" style={styles.titlediv}>
                    <p style={styles.title}>Telecomunicaciones</p>
                </div>
                <div className="descriptiondiv" style={styles.descriptiondiv}>
                    <p style={styles.description}>Desde mensajes de texto o llamadas telefonicas, creamos un medio de comunicacion confiable, certero y personalizado para su empresa.</p>
                </div>
                <div className="knowmorediv" style={styles.knowmorediv}>
                    <p style={styles.knowmore3}>Conoce más</p>
                </div>
                <div className="colorbardiv" style={styles.colorbardiv3}>

                </div>
            </div>
      </div>
    );
  }

  const styles = {
    contentmaster : {width:1440, height:369,background:'#0E0E0E', display: 'flex', flexDirection: 'row',justifyContent: 'space-evenly'},
    content : {marginTop:-64, width:350 , height:264, background:'#1E1E1E', display: 'flex', flexDirection: 'column',},
    pretitlediv: {marginTop: 40, marginLeft: 16, width: 318, height: 24,},
    pretitle: {fontFamily: 'Montserrat-MediumItalic', fontSize: 14, color: '#FFFFFF', marginTop: '0.3em',},
    titlediv: {marginLeft: 16, width: 318, height: 32,},
    title: {fontFamily: 'Montserrat-Bold', fontSize: 22, color: '#FFFFFF', marginTop: '0.23em',},
    descriptiondiv: {marginTop: 8, marginLeft: 16, width: 318, height: 96,},
    description: {fontFamily: 'Montserrat-Regular', fontSize: 16, color: '#FFFFFF', marginTop: '0.3em',lineHeight: 1.4},
    knowmorediv: {marginLeft: 16, marginTop: 16, width: 101, height: 24,},
    knowmore: {fontFamily: 'Montserrat-Bold', fontSize: 14, color: '#0052A4',marginTop: '0.3em'},
    colorbardiv: {width: 350, height: 8, marginTop: 16, backgroundImage: 'linear-gradient(68deg, #10A9CC 4%, #0052A4 100%)', borderRadius: '0 0 2px 2px',},
    knowmore2: {fontFamily: 'Montserrat-Bold', fontSize: 14, color: '#1AA942',marginTop: '0.3em'},
    colorbardiv2: {width: 350, height: 8, marginTop: 16, backgroundImage: 'linear-gradient(68deg, #8DC540 4%, #00A343 100%)', borderRadius: '0 0 2px 2px',},
    knowmore3: {fontFamily: 'Montserrat-Bold', fontSize: 14, color: '#F57320',marginTop: '0.3em'},
    colorbardiv3: {width: 350, height: 8, marginTop: 16, backgroundImage: 'linear-gradient(68deg, #FFB715 4%, #F36422 100%)', borderRadius: '0 0 2px 2px',},
  }

  export default Valueproposition;