import React from "react";
//import "./engage.css";
import logo from "./assets/cubic.png";

function Engage() {
  return (
    <div className="engageMain" style={styles.engageMain}>
      <div className="content" style={styles.content}>
        <div className="textdiv" style={styles.textdiv}>
          <p style={styles.maintext}>Innovamos en proyectos para potencializar los objetivos tu empresa.</p>
        </div>
        <div className="logo" style={styles.logo}>
          <img src={logo} alt="Logo" height="280" width="210" />
        </div>
      </div>

      <div className="divmainbutton" style={styles.divmainbutton}>
        <button className="mainbutton" style={styles.mainbutton}>
          <p className="textmainbutton" style={styles.textmainbutton}>Más información</p>
        </button>
      </div>
    </div>
  );
}

const styles = {
  engageMain: {
    background: '#000000',
    width: 1440,
    height: 512,
},
  content :{
    display: 'flex',
    //flexDirection: 'row',
  },
  textdiv: {
    width: 635,
    height: 168,
    marginLeft: 260,
    marginTop: 12,
    background: '#000000',
  },
  maintext: {
    fontFamily: 'Montserrat-Bold',
    fontSize: 44,
    color: '#FFFFFF',
    
    textShadow: '0 1px 2px rgba(0,0,0,0.20)',
  },
  divmainbutton : {
    marginLeft: 260,
    marginTop: -80,
  },
  mainbutton: {
    backgroundImage: 'linear-gradient(270deg, #87C440 0%, #00A343 100%)',
    borderRadius: 24,
    width: 227,
    height: 48,
  },
  textmainbutton: {
    fontFamily: 'Montserrat-Bold',
    fontSize: 16,
    color: '#FFFFFF',
    textAlign: 'center',
    marginTop: 10,
  },
  logo: {
    marginLeft: 100,
    marginTop: 64,
    height: 280,
    width: 210,
  },
}

export default Engage;
