import React from 'react';
//import './header.css';
import logo from './assets/illustration.png';


function Us() {
    return (
        <div className="card" style={styles.contentmaster}>
            <div className="card" style={styles.content}>
                <div className="us" style={styles.usdiv}>
                    <p style={styles.us}>NOSOTROS</p>
                </div>
                <div className="cubicdiv" style={styles.cubicdiv}>
                    <p style={styles.cubic}>CUBICPIXL</p>
                </div>
                <div className="cubicsubdiv" style={styles.cubicsubdiv}>
                    <p style={styles.cubicsub}>Somos expertos en soluciones digitales</p>
                </div>
                <div className="descriptiondiv" style={styles.descriptiondiv}>
                    <p style={styles.description}>Desarrollamos proyectos centrados en las personas que garantizan el éxito de tu negocio mediante tecnologías de ultima generación para satisfacer las necesidades de tus clientes o tu negocio.</p>
                </div>
                <div className="divmainbutton" style={styles.divmainbutton}>
                    <button className="mainbutton" style={styles.mainbutton}>
                    <p className="textmainbutton" style={styles.textmainbutton}>Contacta a un experto</p>
                    </button>
                </div>
            </div>
            <div className="card" style={styles.content}>
                <div className="us" style={styles.picdiv}>
                <img src={logo} alt="Logo" height="308" width="353" />
                </div>
                
            </div>
            
      </div>
    );
  }

  const styles = {
    contentmaster : {width:1440, height:369,background:'#0E0E0E', display: 'flex', flexDirection: 'row',},
    content : {display: 'flex', flexDirection: 'column',},
    usdiv: {marginLeft: 165, width: 691, height: 24,},
    us: {fontFamily: 'Montserrat-Regular', fontSize: 16, color: '#616161', letterSpacing: 1.28, lineHeight: 1.27, marginTop: '0.3em',},
    cubicdiv: {marginTop: 16, marginLeft: 165, width: 350, height: 40,},
    cubic: {fontFamily: 'Montserrat-Bold', fontSize: 40, color: '#FFFFFF',marginTop: '-0.15em',},
    cubicsubdiv: {marginTop: 8, marginLeft: 165, width: 679, height: 40, },
    cubicsub: {fontFamily: 'Montserrat-Medium', fontSize: 32, color: '#FFFFFF', lineHeight: 1.4, marginTop: '-0.15em',},
    descriptiondiv: {marginTop: 20, marginLeft: 165, width: 730, height: 81,},
    description: {fontFamily: 'Montserrat-Regular', fontSize: 20, color: '#FFFFFF', lineHeight: 1.32, marginTop: '-0.1em',},
    divmainbutton : {
        marginLeft: 164,
        marginTop: 33,
      },
      mainbutton: {
        backgroundImage: 'linear-gradient(270deg, #FFB715 0%, #F5721F 100%)',
        borderRadius: 24,
        width: 239,
        height: 48,
      },
      textmainbutton: {
        fontFamily: 'Montserrat-Bold',
        fontSize: 16,
        color: '#FFFFFF',
        textAlign: 'center',
        marginTop: 10,
      },
      picdiv: {
        marginLeft: 29, width: 353, height: 308,
      }
  }

  export default Us;