import React from "react";
//import "./banner.css";
import logo from './assets/Bitmap.png';

function Banner() {
  const infoIcon = "./assets/Bitmap.png";
  return (
    <div className="card" style={styles.contentmaster}>
      
          <div  style={{ backgroundImage:`url(${logo})`, width: 1308, height: 208, marginTop: 0, marginLeft: 66, backgroundSize: '100%'}}>
            <h1 style={styles.centered} >Nos adaptamos a las necesidades de tu empresa</h1>
            <h1 style={styles.centered1} >para garantizar una entrega de valor constante</h1>
          </div>
      
    </div>
  );
}

const styles = {
  contentmaster : {width:1440, height:208,background:'#0E0E0E', display: 'flex', flexDirection: 'row',},
  
  imgbkg : {
    width: 1308,
    height: 208,
    marginTop: 0,
    marginLeft: 66,
    backgroundImage: `url('./assets/Bitmap.png'})`,
    //backgroundSize: '2668 468',
  },
  centered: {
    position: 'relative',
    top: '50%',
    left: '50%',
    marginTop:-15,
    transform: 'translate(-50%, -50%)',
    fontFamily: 'Montserrat-Bold',
    fontSize: 34,
    color: '#FFFFFF',
    textAlign: 'center',
    lineHeight: 1.44,
    textShadow: '0 1px 2px rgba(0,0,0,0.20)',
  },
  centered1: {
    position: 'relative',
    top: '50%',
    left: '50%',
    marginTop:-35,
    transform: 'translate(-50%, -50%)',
    fontFamily: 'Montserrat-Bold',
    fontSize: 34,
    color: '#FFFFFF',
    textAlign: 'center',
    lineHeight: 1.44,
    textShadow: '0 1px 2px rgba(0,0,0,0.20)',
  }
}

export default Banner;