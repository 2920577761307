import React from "react";
import "./footer.css";
import logo from "./assets/cubic.png";
import fb from "./assets/facebook.png";
import tw from "./assets/twitter.png";
import yt from "./assets/youtube.png";
import ig from "./assets/instagram.png";
import wa from "./assets/whatsapp.png";

function Footer() {
  return (
    <div className="footer">
      <div className="columnastext">
        <div className="loguito">
          <img src={logo} alt="Logo" height="64" width="48" />;
        </div>
        <div className="address">
          <p className="title">CubicPixl</p>
          <p className="subtitle">
            Carretera Jorobas - Tula 29, El Llano 2da Sección, Tula de Allende,
            Hidalgo
          </p>
        </div>
        <div className="contact">
          <p className="title">Contacto</p>
          <p className="subtitle1">+52 773 228 5024 contacto@cubicpixl.com</p>
          <p className="subtitle1"></p>
        </div>
        <div className="followus">
          <p className="title">Síguenos</p>
          <div className="social">
            <img src={fb} alt="Logo" height="24" width="13" />;
            <img
              src={tw}
              className="imgstyle"
              alt="Logo"
              height="24"
              width="30"
            />
            ;
            <img
              src={yt}
              className="imgstyle"
              alt="Logo"
              height="24"
              width="35"
            />
            ;
            <img
              src={ig}
              className="imgstyle"
              alt="Logo"
              height="24"
              width="24"
            />
            ;
            <img
              src={wa}
              className="imgstyle"
              alt="Logo"
              height="24"
              width="24"
            />
            ;
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
