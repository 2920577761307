import React from "react";
import "./targets.css";

function Targets() {
  return (
    <div className="tgtmain">
      
    </div>
  );
}

export default Targets;