import React from "react";
//import "./bsgt.css";

function Bsgt() {
  return (
    <div className="bsgtmain" style={styles.bsgtmain}>
      <div className="contcont" style={styles.contcont}>
        <p className="lblcontact" style={styles.lblcontactp}>CONTACTO</p>
      </div>
      <div className="dogw" style={styles.dogw}>
        <p className="lbldogw" style={styles.lbldogw}>Haz crecer tu negocio</p>
      </div>
      <div className="startbx" style={styles.startbx}>
        <p className="startms"  style={styles.startms}>
          Ya sea que estes comenzando con una idea de negocio o que <br /> seas
          una empresa que desea innovar con tecnología para el futuro,
          contactanos estamos listos.
        </p>
      </div>
      <div className="fields" style={styles.fields}>
        <p className="inputlfl" style={styles.inputlfl}>Completa los campos y un experto se comunicara a la brevedad</p>
      </div>
      <div className="cmpnt" style={styles.cmpnt}>
        
      </div>
    </div>
  );
}

const styles = {
  bsgtmain: {
    display: 'inline-block', 
    flexDirection: 'column',
    height: 692,
    width: 1440,
    background: '#0e0e0e'
  },
  contcont: {
    width: 126,
    height: 24,
    marginLeft: 657,
  },
  lblcontactp: {
    fontFamily: 'Montserrat-Regular',
    fontSize: 16,
    color: '#616161',
    letterSpacing: 1.28,
    textAlign: 'center',
    lineHeight: 27,
  },
  dogw: {
    width: 540,
    height: 40,
    marginTop: 232,
    marginLeft: 450,
  },
  lbldogw: {
    fontFamily: 'Montserrat-Bold',
    fontSize: 40,
    color: '#ffffff',
    textAlign: 'center',
    lineHeight: 1.49,
  },
  startbx: {
    width: 730,
    height: 82,
    marginTop: 20,
    marginLeft: 355,
  },
  startms: {
    fontFamily: 'Montserrat-Regular',
    fontSize: 20,
    color: '#ffffff',
    textAlign: 'center',
    lineHeight: 1.27,
  },
  fields: {
    width: 454,
    height: 24,
    marginTop: 16,
    marginLeft: 493,
  },
  inputlfl: {
    fontFamily: 'Montserrat-Medium',
    fontSize: 14,
    color: '#b4d232',
    lineHeight: 1.18,
  },
  cmpnt: {
      width: 920,
      height: 48,
      marginTop: 16,
      marginLeft: 260,
      backgroundColor: 'crimson',
   
  }
}

export default Bsgt;
