import React from 'react';
//import './header.css';


function Header() {
    return (
      <div className="header" style={{width:1440 , height:80, background:'#000000'}}>
        
        
      </div>
    );
  }

  export default Header;